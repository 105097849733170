import classNames from "classnames"
import React, { ComponentProps, useContext, useRef } from "react"

import { Color } from "../../../../constants/V2/color"
import PillButton from "../../../elements/V2/Buttons/PillButton"
import TextButton from "../../../elements/V2/Buttons/TextButton"
import Callout from "../../../elements/V2/Callout"
import GatsbyStoryblokImage from "../../../elements/V2/GatsbyStoryblokImage"
import Grid from "../../../elements/V2/Grid"
import Section from "../../../elements/V2/Section"
import StickyContainer from "../../../elements/V2/StickyContainer"
import Typography from "../../../elements/V2/Typography"
import Video from "../../../elements/V2/Video"
import { NavigationV2Context } from "../../Navigation/context"

import { useElementObserver } from "@hooks/V2/useElementObserver"
import { getBackgroundColorClass, getAccentColor } from "@utils/V2/color"
import { isMobile } from "@utils/V2/screen"

type Props = {
  headingText: string
  bodyText: string
  superscriptText?: string
  backgroundColor: Color
  primaryButtonText: string
  primaryButtonLink: string
  primaryButtonMobileLink?: string
  primaryButtonBorderColor: Color
  secondaryButtonText: string
  secondaryButtonLink: string
  videoUrl?: string
  imageUrl: string
  imageAlt: string
  mobileImageUrl?: string
  mobileImageAlt?: string
  showCallout: boolean
}

const FloodHero: React.FC<Props> = ({
  headingText,
  bodyText,
  superscriptText,
  backgroundColor,
  primaryButtonText,
  primaryButtonLink,
  primaryButtonMobileLink,
  primaryButtonBorderColor,
  secondaryButtonText,
  secondaryButtonLink,
  videoUrl,
  imageUrl,
  imageAlt,
  mobileImageUrl,
  mobileImageAlt,
  showCallout = false,
  ...props
}) => {
  const navigationContext = useContext(NavigationV2Context)
  const textContainerRef = useRef(null)
  const { isVisible: isCtaVisible, startObserving } = useElementObserver(
    textContainerRef
  )
  const accentColor = getAccentColor(backgroundColor)

  if (isMobile()) {
    startObserving()
  }

  const imageProps: Omit<
    ComponentProps<typeof GatsbyStoryblokImage>,
    "image" | "alt"
  > = {
    loadType: "eager",
    quality: 80,
    aspectRatio: "fullWidth",
    className: "sm-v2:min-h-screen sm-v2:w-auto",
  }

  return (
    <div
      className={classNames(
        "relative -mb-lg-v2",
        getBackgroundColorClass(backgroundColor)
      )}
      {...props}
    >
      {videoUrl ? (
        <Video
          video={videoUrl}
          coverImage={imageUrl}
          ImageAlt={imageAlt}
          loadType="eager"
          aspectRatio="fullWidth"
          playbackMode="autoplay"
          className="sm-v2:min-h-screen sm-v2:w-full"
          playOnMobile={false}
        />
      ) : (
        <>
          <GatsbyStoryblokImage
            {...imageProps}
            image={imageUrl}
            alt={imageAlt}
            className={classNames(imageProps.className, "hidden sm-v2:block")}
          />
          <div className="relative sm-v2:hidden">
            <GatsbyStoryblokImage
              {...imageProps}
              image={mobileImageUrl ?? imageUrl}
              alt={mobileImageAlt ?? imageAlt}
              className={imageProps.className}
            />
            <div className="absolute bottom-0 w-full h-1/2 bg-gradient-to-b from-transparent to-black-v2" />
          </div>
        </>
      )}

      <Section className="relative sm-v2:absolute bottom-20-v2 left-0 py-32-v2 sm-v2:py-gutter-sm-v2 md-v2:py-gutter-md-v2 lg-v2:py-gutter-lg-v2 xl-v2:py-gutter-xl-v2">
        <Grid>
          <div
            ref={textContainerRef}
            className="col-span-full sm-v2:col-span-2 md-v2:col-start-1 md-v2:col-end-4 lg-v2:col-start-1 lg-v2:col-end-6 flex flex-col gap-8-v2 md-v2:mb-8-v2 lg-v2:mb-16-v2"
          >
            {superscriptText && (
              <Typography
                text={superscriptText}
                font="grotesk"
                weight="book"
                size="subscript-lg"
                color={accentColor}
                className="uppercase"
              />
            )}

            {showCallout && (
              <Callout
                color={getAccentColor(backgroundColor)}
                classname="my-16-v2"
              />
            )}

            <Typography
              text={headingText}
              font="grotesk"
              weight="medium"
              size="h2"
              color={accentColor}
              element="h1"
            />
            <Typography
              text={bodyText}
              font="grotesk"
              weight="book"
              size="body-md"
              color={accentColor}
              nonResponsive
              className="sm-v2:hidden"
            />
            <Typography
              text={bodyText}
              font="grotesk"
              weight="book"
              size="body-lg"
              color={accentColor}
              nonResponsive
              className="hidden sm-v2:block"
            />
          </div>
          <div className="col-span-full flex items-center flex-col gap-20-v2 md-v2:gap-24-v2 sm-v2:flex-row">
            <PillButton
              size="large"
              style="solid"
              text={primaryButtonText}
              color={primaryButtonBorderColor}
              linkUrl={primaryButtonLink}
              mobileLinkUrl={primaryButtonMobileLink}
              className="w-full sm-v2:w-max"
            />
            <TextButton
              style="text"
              text={secondaryButtonText}
              linkUrl={secondaryButtonLink}
              color={accentColor}
            />
          </div>
          <StickyContainer
            isVisible={
              isMobile() &&
              typeof isCtaVisible === "boolean" &&
              !isCtaVisible &&
              !navigationContext.isMobileMenuOpen
            }
          >
            <PillButton
              size="large"
              style="solid"
              text={primaryButtonText}
              overrideTextColor={accentColor}
              color={primaryButtonBorderColor}
              linkUrl={primaryButtonLink}
              mobileLinkUrl={primaryButtonMobileLink}
            />
          </StickyContainer>
        </Grid>
      </Section>
    </div>
  )
}

export default FloodHero
